<div class="mt-8 flex flex-row-reverse">
    <lib-loading-button
        class="block"
        [loading]="loading_ok()"
        [disabled]="disable_ok()" 
        (onClick)="onOK.emit()">
        {{ label_ok() }}
    </lib-loading-button>
        
    <button class="mr-1"
        type="button" 
        mat-stroked-button 
        (click)="onCancel.emit()">
        {{ label_cancel() }}
    </button>
</div>