import {
	ChangeDetectionStrategy,
	Component,
	input,
	output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingButtonComponent } from '../loading-button/loading-button.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
	selector: 'lib-ok-cancel',
	imports: [CommonModule, LoadingButtonComponent, MatButtonModule],
	templateUrl: './ok-cancel.component.html',
	styleUrl: './ok-cancel.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OkCancelComponent {
	label_ok = input<string>('OK');
	label_cancel = input<string>('Cancel');

	disable_ok = input<boolean>(false);
	disable_cancel = input<boolean>(false);

	loading_ok = input<boolean>(false);
	hide_cancel = input<boolean>(false);

	onOK = output<void>();
	onCancel = output<void>();
}
