import { ParamMap } from "@angular/router";
import { LoggerService } from "../services/logger.service";
import { inject } from "@angular/core";

export function getRouteParameter(paramMap: ParamMap, msg: string, idParamName: string = 'id', mandatory: boolean = true) {
    
    const logger: LoggerService = inject(LoggerService);
    const id = paramMap.get(idParamName);

    if (id === null) {
        if(mandatory) {
            logger.error(`${msg} - param \'${idParamName}\' not found !`);
        }
        return undefined;
    }

    if (isNaN(+id)) {
        if(mandatory) {
            logger.error(`${msg} - param \'${idParamName}\' not a number: \'${id}\' !`);
        }
        return undefined;
    }

    return +id;
}